@import '~newbalance/global/theme'; // stylelint-disable-line

// This is for the "How early access works" page designer page. Since the page designer components don't allow
// for custom classes to be added, we must piggy back off of the custom ID added to the parent container, along with the classes applied
// by page designer. The important flag is needed so our styling overwrites the ootb stylng in the components.

#how-early-access-works {
    .experience-component {
        &.experience-commerce_assets-TextComponent {
            &:nth-child(even) {
                .text-component-container .row {
                    padding-top: 48px !important;
                }
            }

            &:nth-child(odd) {
                .text-component-container .row {
                    padding-bottom: 48px !important;
                }
            }

            &:first-child {
                .text-component-container .row {
                    padding-top: 0 !important;
                }
            }

            &:last-child {
                .text-component-container .row {
                    padding-bottom: 48px !important;
                    padding-top: 96px !important;
                }
            }

            .row {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

            .text-component-container {
                li,
                p {
                    padding-left: 2rem !important;
                    padding-right: 2rem !important;
                    @include media-breakpoint-up(lg) {
                        padding-left: 10rem !important;
                        padding-right: 10rem !important;
                    }
                }

                padding-right: 0 !important;
                padding-left: 0 !important;
                max-width: 100rem;
            }
        }

        &.experience-commerce_assets-heroBanner {
            @include media-breakpoint-up (md) {
                max-width: 100rem;
                margin: auto;
            }
        }
    }

    .hero-banner-title * {
        font-family: $nb-page-heading-font;
        font-size: 56px;
        line-height: 3.5rem;
        width: 75%;
    }
}

// Note: after chatting with the design team, they want us to use this color
// scheme for our button. Eventually we will have a todo, which is to make this
// globally, but for now our button will use this. This is to ensure our button
// is ADA compliant in our experience.
.nb-button.energy-launch-button {
    &.disabled {
        background: #efefef;
        border-color: #efefef;
        color: #6b6b6b !important;
    }
}
